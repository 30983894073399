import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from './appointments.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { AppointmentService } from '../appointment.service';
import { Appointment } from '@models/appointments.interface';

@Injectable()
export class AppointmentsEffects {
  constructor(private actions$: Actions, private appointmentService: AppointmentService) {}

  getLeadTimeline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAppointmentsAction),
      switchMap(() => {

        return this.appointmentService.getAppointments().pipe(
          map((appointments: Array<Appointment>) => {
            return fromActions.getAppointmentsSuccess({
              appointments,
            });
          }),
          catchError(() => of(fromActions.getAppointmentsFailure()))
        );
      })
    )
  );
}
