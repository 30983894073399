import { Lead } from '@models/lead.interface';

export interface LeadsState {
  leads: Lead[];
  selectedLead: Lead | null;
  selectedLeadTimeline: any | null;
}

export const initialLeadsState: LeadsState = {
  leads: [],
  selectedLead: null,
  selectedLeadTimeline: null,
};
