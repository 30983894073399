import { NgModule } from '@angular/core';
import { LayoutModule } from './layout/layout.module';
import { AppStoreModule } from '../store/app.store';
import { CommonModule } from '@angular/common';
import { AppConfigModule } from '../app-config';
import { AuthGuard } from './guards/auth.guard';

@NgModule({
  declarations: [],
  imports: [CommonModule,AppConfigModule, LayoutModule],
  providers: [AuthGuard],

})
export class CoreModule {}
