import { NgModule } from '@angular/core';

import { AuthGuard } from './auth.guard';
import {AppConfigModule} from "../../app-config";
import {AuthService} from "@services/auth.service";


@NgModule({
  imports: [AppConfigModule.forChild()],
  declarations: [],
  exports: [],
  providers: [AuthGuard, AuthService],
})
export class GuardsModule {}
