import { createReducer, on } from '@ngrx/store';
import * as LeadActions from './leads.actions';
import { initialLeadsState } from './leads.state';

export const leadReducer = createReducer(
  initialLeadsState,
  on(LeadActions.getSalesLeadsActionSuccess, (state, { leads }) => ({
    ...state,
    leads,
  })),
  on(LeadActions.getPurchasesLeadsActionSuccess, (state, { leads }) => ({
    ...state,
    leads,
  })),
  on(LeadActions.addLead, (state, { lead }) => ({
    ...state,
    leads: [...state.leads, lead],
  })),
  on(LeadActions.removeLead, (state, { leadId }) => ({
    ...state,
    leads: state.leads.filter(lead => lead._id !== leadId),
  })),
  on(LeadActions.setSelectedLead, (state, { lead }) => ({
    ...state,
    selectedLead: lead,
  })),
  on(LeadActions.updateSelectedLead, (state, { lead }) => ({
    ...state,
    selectedLead: Object.assign({}, state.selectedLead, lead),
    leads: state.leads.map(l => {
      console.log(l, lead);

      if (l._id == state.selectedLead!._id) {
        return Object.assign({}, l, lead);
      }
      return l;
    }),
  })),
  on(LeadActions.getLeadTimelineSuccess, (state, { leadTimeline }) => ({
    ...state,
    selectedLeadTimeline: leadTimeline,
  }))
);
