import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { AppConfigModule } from './app-config';
import { environment } from '@env/environment';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { registerLocaleData } from '@angular/common';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {FlatpickrModule} from "angularx-flatpickr";
import { GuardsModule } from '@core/guards';
import { AuthService } from '@services/auth.service';
import { AppStoreModule } from './store/app.store';
import { CookieService } from 'ngx-cookie-service';
import { HttpErrorInterceptor } from '@core/interceptors/http-error.interceptor';
import { SetHeaderInterceptor } from '@core/interceptors/set-header.interceptor';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    CoreModule,
    AppConfigModule.forRoot({
      apiGatewayURL: environment.apiGatewayURL,
      applicationId: environment.applicationId,
      apiPortaleURL: environment.apiPortaleURL,
      redirectUrl: environment.redirectUrl,
      apiProxiedUrl: environment.apiGatewayURL,
    }),
    ToastrModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    FlatpickrModule.forRoot(),
    GuardsModule,
    AppStoreModule,
    MessagesModule,
  ],
  declarations: [AppComponent],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SetHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    CookieService,
    AuthService,
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    // provider used to create fake backend, remove this (all or single part) if real backend is connected
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
