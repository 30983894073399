import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {UtilityService} from "@services/utility.service";
import {Appointment} from "@models/appointments.interface";

@Injectable({ providedIn: 'root' })
export class AppointmentService {
  constructor(
    private http: HttpClient,
    public utilityService: UtilityService
  ) {}

  getAppointments(): Observable<Array<Appointment>> {
    return this.http.get<any>(this.utilityService.getApiEndpoint() + '/events');
  }

}
