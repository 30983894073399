import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appReducers } from './app.reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@env/environment';
import { localStorageSync } from 'ngrx-store-localstorage';
import { LeadsStoreModule } from '../modules/leads/store/leads.store';
import {AuthStoreModule} from "../modules/user/store/auth.store";
import {AppointmentsStoreModule} from "../modules/appointments/store/appointments.store";
import {FilesStoreModule} from "../modules/files/store/files.store";
import { AuthService } from '@services/auth.service';

export const localStorageSyncReducer = (
  reducer: ActionReducer<any>
): ActionReducer<any> =>
  localStorageSync({
    keys: ['auth', 'leads', 'appointments','files'],
    rehydrate: true,
    storage: localStorage,
    storageKeySerializer: key => `app_${key}`,
  })(reducer);
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  imports: [
    CommonModule,
    AuthStoreModule,
    LeadsStoreModule,
    AppointmentsStoreModule,
    FilesStoreModule,
    StoreModule.forRoot(appReducers, { metaReducers }),
    EffectsModule.forRoot([]),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: environment.production,
        })
      : [],
  ],
  providers: [AuthService],
})
export class AppStoreModule {}
