import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@services/auth.service';
import { switchMap, map, catchError, of } from 'rxjs';
import { loginSuccess, loginFailure } from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Login] User Login'),
      switchMap(({ username, password }) =>
        this.authService.authenticate(username, password).pipe(
          map(token => loginSuccess( token )),
          catchError(error => of(loginFailure({ error })))
        )
      )
    )
  );
}
