import { createReducer, on } from '@ngrx/store';
import * as Actions from './auth.actions';
import { initialAuthState } from './auth.state';

export const authReducer = createReducer(
  initialAuthState,
  on(Actions.login, state => ({ ...state, isLoading: true })),
  on(Actions.loginSuccess, (state, { token }) => ({ ...state, token, isLoading: false })),
  on(Actions.loginFailure, (state, { error }) => ({ ...state, error, isLoading: false })),
  on(Actions.logoutAction, state => ({ ...state, token: '' }))
);
