import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appointmentsReducer } from './appointments.reducers';
import {AppointmentsEffects} from "./appointments.effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('appointments', appointmentsReducer),
    EffectsModule.forFeature([AppointmentsEffects]),
  ],
  providers: [],
})
export class AppointmentsStoreModule {}
