import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from './leads.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { LeadService } from '@services/lead.service';
import { take } from 'rxjs/operators';
import { Timeline } from '@models/timeline.interface';

@Injectable()
export class LeadEffects {
  constructor(private actions$: Actions, private leadService: LeadService) {}

  getSalesLeadsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSalesLeadsAction),
      switchMap(() => {
        return this.leadService.getProjectSales().pipe(
          take(1),
          map((leads: any) => {
            return fromActions.getSalesLeadsActionSuccess({
              leads,
            });
          }),
          catchError(() => of(fromActions.getSalesLeadsActionFailure()))
        );
      })
    )
  );

  getPurchasesLeadsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getPurchasesLeadsAction),
      switchMap(() => {
        return this.leadService.getProjectPurchases().pipe(
          take(1),
          map((leads: any) => {
            return fromActions.getPurchasesLeadsActionSuccess({
              leads,
            });
          }),
          catchError(() => of(fromActions.getPurchasesLeadsActionFailure()))
        );
      })
    )
  );

  getLeadTimeline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getLeadTimeline),
      switchMap(({ lead }) => {
        return this.leadService.getLeadTimeline(lead._id).pipe(
          take(1),
          map((leadTimeline: Timeline) => {
            return fromActions.getLeadTimelineSuccess({
              leadTimeline,
            });
          }),
          catchError(() => of(fromActions.getLeadTimelineFailure()))
        );
      })
    )
  );
}
