import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageContainerComponent } from '@core/layout/page-container/page-container.component';
import { SalesPageComponent } from './modules/sales/components/sales-page.component';
import { PurchasesPageComponent } from './modules/purchases/components/purchases-page.component';
import {AuthGuard} from "@core/guards/auth.guard";

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path: '',
    component: PageContainerComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard], //TODO
        loadChildren: () =>
          import('./modules/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'home/sales',
        canActivate: [AuthGuard], //TODO
        component: SalesPageComponent,
      },
      {
        path: 'home/purchases',
        canActivate: [AuthGuard], //TODO
        component: PurchasesPageComponent,
      },
      {
        path: 'home/appointments',
        canActivate: [AuthGuard], //TODO
        loadChildren: () =>
          import('./modules/appointments/appointments.module').then(m => m.AppointmentsModule),
      },
      {
        path: 'home/calendar',
        canActivate: [AuthGuard], //TODO
        loadChildren: () =>
          import('./modules/calendar/calendar.module').then(m => m.CalendarModule),
      },
      {
        path: 'home/files',
        canActivate: [AuthGuard], //TODO
        loadChildren: () =>
          import('./modules/files/files.module').then(m => m.FilesModule),
      },
    ],
  },
  {
    path: 'sales',
    canActivate: [AuthGuard], //TODO
    loadChildren: () =>
      import('./modules/sales/sales.module').then(m => m.SalesModule),
  },
  {
    path: 'purchases',
    canActivate: [AuthGuard], //TODO
    loadChildren: () =>
      import('./modules/purchases/purchases.module').then(m => m.PurchasesModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
