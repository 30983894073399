import { environment } from '@env/environment';
import { FusionauthService } from 'src/app/client';

export function appInitializer(fusionauthService: FusionauthService) {
  return () =>
  new Promise((resolve) => {
    fusionauthService.refreshToken( environment.applicationId).subscribe();
    setInterval(() => fusionauthService.refreshToken( environment.applicationId).subscribe(), 300000);
    resolve(null);
  });
}
