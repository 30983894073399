import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from './files.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';
import {FileService} from "@services/file.service";

@Injectable()
export class FilesEffects {
  constructor(private actions$: Actions, private fileService: FileService) {}

  getFilesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getFilesAction),
      switchMap(() => {
        return this.fileService.getFiles().pipe(
          take(1),
          map((files: any) => {
            return fromActions.getFilesActionSuccess({
              files,
            });
          }),
          catchError(() => of(fromActions.getFilesActionFailure()))
        );
      })
    )
  );

}
