import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { IAppConfig } from './interfaces/app-config.interface';
import {appInitializer} from "@services/app-init.service";
import {AuthService} from "@services/auth.service";

@NgModule({
  providers: [{ provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService] }],
})
export class AppConfigModule {
  constructor(@Optional() @SkipSelf() parentModule: AppConfigModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: IAppConfig): ModuleWithProviders<AppConfigModule> {
    return {
      ngModule: AppConfigModule,
      providers: [
        AppConfigService,
        {
          provide: 'APP_CONFIG',
          useValue: config,
        },
      ],
    };
  }

  static forChild(): ModuleWithProviders<AppConfigModule> {
    return {
      ngModule: AppConfigModule,
      providers: [AppConfigService],
    };
  }
}
