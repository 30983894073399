import {initialLeadsState, LeadsState} from "../modules/leads/store/leads.state";
import {AuthState, initialAuthState} from "../modules/user/store/auth.state";

export interface AppState {
  auth: AuthState;
  leads: LeadsState;
}

export const initialAppState: AppState = {
  auth: initialAuthState,
  leads: initialLeadsState
};
