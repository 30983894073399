import { createAction, props } from '@ngrx/store';

export const resetAuthAction = createAction('[Auth] Reset Store');

export const login = createAction(
  '[Login] User Login',
  props<{ username: string, password: string }>()
);
export const logoutAction = createAction('[Auth] Logout');

export const loginSuccess = createAction(
  '[Login] Login Success',
  props<{ token: string }>()
);

export const loginFailure = createAction(
  '[Login] Login Failure',
  props<{ error: string }>()
);

