import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {authReducer} from './auth.reducers';
import {AuthEffects} from './auth.effects';
import { AuthService } from '@services/auth.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([AuthEffects])
  ],
  providers: [AuthService],
})
export class AuthStoreModule {
}
