import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { Observable } from 'rxjs';
import { Lead } from '@models/lead.interface';

@Injectable({ providedIn: 'root' })
export class FileService {
  constructor(
    private http: HttpClient,
    public utilityService: UtilityService
  ) {}

  getFiles(): Observable<Array<Lead>> {
    return this.http.get<any>(this.utilityService.getApiEndpoint() + '/files');
  }
}
