import { createReducer, on } from '@ngrx/store';
import * as LeadActions from './files.actions';
import { initialFilesState } from './files.state';

export const filesReducer = createReducer(
  initialFilesState,
  on(LeadActions.getFilesActionSuccess, (state, { files }) => ({
    ...state,
    files,
  }))
);
