export type AuthState = {
  token: string;
  error: string;
  isLoading: boolean;
};

export const initialAuthState: AuthState = {
  token: '',
  error: '',
  isLoading: false,
};
