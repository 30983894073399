import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { leadReducer } from './leads.reducers';
import { LeadEffects } from './leads.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('leads', leadReducer),
    EffectsModule.forFeature([LeadEffects]),
  ],
  providers: [],
})
export class LeadsStoreModule {}
