import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, of, switchMap } from 'rxjs';
import { AppConfigService } from '../../app-config';
import { IJwtPayload } from '@core/models/jwt-payload.interface';
import { CacheEnum, cacheResponse } from '@core/utils/cache.utils';
import { User } from '@models/user.interface';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private router: Router
  ) {}

  authorize(applicationId: string, redirectUrl: string): Observable<any> {
    console.log('applicationId ', applicationId);
    console.log('redirectUrl ', redirectUrl);
    const url = encodeURIComponent(redirectUrl);
    return this.http
      .get<any>(
        `${this.appConfigService.getApiProxiedURL()}/fusionauth/authorize?applicationId=${applicationId}&redirectUrl=${url}`
      )
      .pipe(
        switchMap(res => {
          console.log('res: ', res);
          window.location.href = res.url;
          return of(false);
        })
      );
  }
  authenticate(loginId: string, password: string): Observable<any> {
    return this.http
      .post<any>(`${this.appConfigService.getApiProxiedURL()}/fusionauth/authenticate`, {
        applicationId: this.appConfigService.getApplicationId(),
        loginId,
        password,
      })

  }

  logout(applicationId: string) {
    this.http
      .get<any>(`${this.appConfigService.getApiProxiedURL()}/fusionauth/logout/${applicationId}`)
      .subscribe(res => {
        window.location.href = res.url;
        this.router.navigate(['/login']);
      });
  }

  refreshToken(): Observable<any> {
    return this.http.get<any>(
      `${this.appConfigService.getApiProxiedURL()}/fusionauth/refreshToken/${this.appConfigService.getApplicationId()}`
    );
  }

  user(): Observable<IJwtPayload> {
    return cacheResponse(
      CacheEnum.AUTH_USER_CACHE,
      this.http.get<IJwtPayload>(`${this.appConfigService.getApiProxiedURL()}/fusionauth/getAuthenticatedUser`)
    );
  }

  userRegistrations(id: string): Observable<User> {
    return cacheResponse(
      CacheEnum.USER_REGISTRATION,
      this.http.get<User>(`${this.appConfigService.getApiProxiedURL()}/fusionauth/user/${id}`)
    );
  }

  getUserRoles(): Observable<string[]> {
    return this.user().pipe(map(res => res.roles!));
  }
}
