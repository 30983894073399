import { createReducer, on } from '@ngrx/store';
import * as AppointmentsActions from './appointments.actions';
import { initialAppointmentState } from './appointment.state';

export const appointmentsReducer = createReducer(
  initialAppointmentState,
  on(AppointmentsActions.getAppointmentsSuccess, (state, {appointments}) => ({
    ...state,
    appointments: appointments,
  })),
  on(AppointmentsActions.setSelectedAppointment, (state, {appointment}) => ({
    ...state,
    selectedAppointment: appointment,
  })),
  on(AppointmentsActions.removeSelectedAppointment, (state) => ({
    ...state,
    selectedAppointment: null,
  })),
);
