import {Appointment} from "@models/appointments.interface";

export interface AppointmentState {
  appointments: Appointment[];
  selectedAppointment: Appointment | null;
}

export const initialAppointmentState: AppointmentState = {
  appointments: [],
  selectedAppointment: null
};
