import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { filesReducer } from './files.reducers';
import { FilesEffects } from './files.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('files', filesReducer),
    EffectsModule.forFeature([FilesEffects]),
  ],
  providers: [],
})
export class FilesStoreModule {}
